import React, { useEffect } from "react";
import "./index.css";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { messages as enMessages } from "./locales/en/messages";
import { messages as msMessages } from "./locales/ms/messages";
import { messages as zhMessages } from "./locales/zh/messages";
import ErrorPage from "./404";
import PRNLayout from "./prn-layout";
import LandingPage from "./landing-page";
import ReactGA from "react-ga4";
import { createRoot } from "react-dom/client";

i18n.load({
  en: enMessages,
  ms: msMessages,
  zh: zhMessages,
});

i18n.activate("en");

export const stateRoutes = [
  {
    path: "overall",
    element: <LandingPage />,
  },
  {
    path: "selangor",
    element: <LandingPage />,
  },
  {
    path: "penang",
    element: <LandingPage />,
  },
  {
    path: "kelantan",
    element: <LandingPage />,
  },
  {
    path: "terengganu",
    element: <LandingPage />,
  },
  {
    path: "kedah",
    element: <LandingPage />,
  },
  {
    path: "negeri-sembilan",
    element: <LandingPage />,
  },
];

const App: React.FunctionComponent = () => {
  useEffect(() => {
    const locale = window.location.pathname.split("/")[1];
    if (locale === "" || !availableLocale.includes(locale)) {
      i18n.activate("en");
    } else {
      i18n.activate(locale);
    }
  }, []);

  const availableLocale = ["en", "ms", "zh"];

  const gaKey = process.env.REACT_APP_GA_KEY || "G-7MGHDVTE66";
  ReactGA.initialize(gaKey);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PRNLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <LandingPage />,
          children: stateRoutes,
        },
        {
          path: "en",
          element: <LandingPage />,
          children: stateRoutes,
        },
        {
          path: "ms",
          element: <LandingPage />,
          children: stateRoutes,
        },
        {
          path: "zh",
          element: <LandingPage />,
          children: stateRoutes,
        },
      ],
    },
  ]);

  const helmetContext = {};

  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <I18nProvider i18n={i18n}>
          <RouterProvider router={router} />
        </I18nProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

// Exclamation mark for TS is used
const root = createRoot(document.getElementById("root")!);
root.render(<App />);
