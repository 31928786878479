import React from "react";
import QRCode from "react-qr-code";
import useOneLinkUrl, { prnDeepLink } from "./hooks/useOneLinkUrl";
import { useLocation } from "react-router-dom";

interface IQrCodeContainerProps {
  size?: "large" | "medium" | "small";
  text: JSX.Element | string;
  initialUrl: string;
  textColor?: string;
}

const SIZE = {
  small: {
    qrSize: 66,
  },
  medium: {
    qrSize: 80,
  },
  large: {
    qrSize: 100,
  },
};

const QrCodeContainer: React.FunctionComponent<IQrCodeContainerProps> = ({
  size = "small",
  text,
  initialUrl,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const small = size === "small";
  const deepLinkPath =
    prnDeepLink.find((link) => link.path === path)?.deepLinkPath || "/prn-2023";
  const url = useOneLinkUrl(initialUrl, deepLinkPath);

  return (
    <div
      className={`qr-container flex items-center bg-white drop-shadow-custom ${
        small ? "rounded-lg p-2" : "rounded-2xl p-4"
      } space-x-4`}
    >
      <QRCode value={url as string} size={SIZE[size].qrSize} />
      <div>
        <p className={`w-48 flex-1 pb-2 font-poppins text-xl`}>{text}</p>
        <div className="flex justify-around">
          <img src="/three-qrs.png" alt="QR Images" width={190} height={20} />
        </div>
      </div>
    </div>
  );
};

export default QrCodeContainer;
