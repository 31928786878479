import { Trans } from "@lingui/macro";
import {
  IoChevronForwardOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import CountdownTimer from "./countdown-timer";
import "../index.css";
import ReactGA from "react-ga4";
import { useLocaleContext } from "../lib/localeContext";
import Banner from "./banner";
import { TSortedState } from "../landing-page";
import { COALITIONS } from "../data/coalition";

interface IOverviewSectionProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  countdownTime: string;
  countdownEnd: Boolean;
  sortedStates: TSortedState[];
  allCompleted: Boolean;
  setCountdownEnd: React.Dispatch<React.SetStateAction<boolean>>;
}

const OverviewSection: React.FunctionComponent<IOverviewSectionProps> = ({
  setDialogOpen,
  countdownTime,
  countdownEnd,
  sortedStates,
  allCompleted,
  setCountdownEnd,
}) => {
  const locale = useLocaleContext();

  return (
    <>
      {!countdownEnd ? (
        <>
          <Banner setDialogOpen={setDialogOpen} />
          <div className="text-center mt-8">
            <p className="font-poppins font-bold text-3xl text-text-darkest">
              <Trans>Countdown till Elections</Trans>
            </p>
            <p className="font-sen text-lg text-text-dark">
              <Trans>Be the first to know when results are live!</Trans>
            </p>
            <CountdownTimer
              targetDate={countdownTime}
              setCountdownEnd={setCountdownEnd}
            />
            <div className="inline-flex mb-8 rounded-lg bg-off-white p-4 items-center space-x-2 mt-4 cursor-pointer">
              <IoNotificationsOutline
                className="h-9 w-7 cursor-pointer text-primary4"
                aria-hidden="true"
              />
              <p
                className="font-sen font-bold text-primary4"
                onClick={() => {
                  ReactGA.event("prn2023_remind_me_box_clicked", {
                    page_language: locale,
                  });
                  setDialogOpen(true);
                }}
              >
                <Trans>Remind me</Trans>
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-around md:justify-start">
            <div className="flex items-center">
              <p className="font-poppins text-2xl mr-2 py-2 font-bold text-text-darkest">
                <Trans>Overview</Trans>
              </p>
              <div className="blink-dot inline-block rounded-md bg-alert px-2 py-1 font-sen text-white">
                {!allCompleted && <Trans>LIVE</Trans>}
              </div>
            </div>
          </div>
          <div className="md:flex md:justify-between mb-4 text-center">
            <p className="font-sen text-text-dark text-lg">
              <Trans>See which party is in the lead for each state</Trans>
            </p>
            <div>
              <span className="font-sen text-text-lightest mr-1">
                <Trans>Last auto update</Trans>
              </span>
              <span className="font-sen text-text-lightest">
                {new Date().toLocaleTimeString("en-US")}
              </span>
            </div>
          </div>
          <Banner setDialogOpen={setDialogOpen} />
        </>
      )}
      <div className="md:grid md:grid-cols-2 md:gap-4">
        {sortedStates &&
          sortedStates.map((state) => (
            <div
              className="inline-flex mb-4 rounded-lg bg-off-white p-4 items-center space-x-2 justify-between w-full cursor-pointer"
              key={state.stateCode}
              onClick={() => {
                ReactGA.event("prn2023_states_view_more", {
                  page_language: locale,
                  state_selected: state.stateName,
                });
                setDialogOpen(true);
              }}
            >
              <div>
                <p className="font-poppins text-xl font-bold text-text-darkest">
                  {state.stateNameLocale}
                </p>
                {!countdownEnd || state.electionStatus === "unstarted" ? (
                  <p className="font-sen text-text-dark">
                    {locale == "zh"
                      ? COALITIONS[state.winningParty]?.["zh"] ||
                        state.winningParty
                      : state.winningParty}{" "}
                    <Trans>is incumbent</Trans>
                  </p>
                ) : state.electionStatus === "completed" ? (
                  <p className="font-sen font-bold text-text-darkest">
                    {locale == "zh"
                      ? COALITIONS[state.winningParty]?.["zh"] ||
                        state.winningParty
                      : state.winningParty}{" "}
                    <Trans>wins 🏆</Trans>
                  </p>
                ) : state.electionStatus === "ongoing" ? (
                  <div className="flex items-center space-x-2">
                    <div className="bg-alert w-2 h-2 rounded-lg blink-dot"></div>
                    <p className="font-sen text-text-dark">
                      {locale == "zh"
                        ? COALITIONS[state.mappedCoalitions[0].code]?.["zh"] ||
                          state.mappedCoalitions[0].code
                        : state.mappedCoalitions[0].code}{" "}
                      <Trans>is leading</Trans>
                    </p>
                  </div>
                ) : state.electionStatus === "tallying" ? (
                  <p className="font-sen text-text-dark">
                    <Trans>Tallying soon...</Trans>
                  </p>
                ) : null}
              </div>
              <div className="flex items-center space-x-4">
                {state.electionStatus === "unstarted" ||
                state.electionStatus === "tallying" ||
                !countdownEnd ? (
                  <div className="w-12 h-12 bg-disabled rounded-md"></div>
                ) : (
                  state.mappedCoalitions &&
                  state.mappedCoalitions[0].imageUrl !== "" && (
                    <img
                      src={state.mappedCoalitions[0].imageUrl}
                      className={`w-12 h-12 rounded-lg ${
                        state.electionStatus === "ongoing" && "opacity-50"
                      }`}
                      alt={state.mappedCoalitions[0].partyName}
                    />
                  )
                )}
                <IoChevronForwardOutline
                  className="h-5 w-5 text-primary3 cursor-pointer"
                  aria-hidden="true"
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default OverviewSection;
