import { Trans } from "@lingui/macro";
import { IoChevronForwardOutline } from "react-icons/io5";
import rgbHex from "rgb-hex";
import { TCoalition, TSortedState } from "../landing-page";
import { COALITIONS } from "../data/coalition";
import { useLocaleContext } from "../lib/localeContext";
import { useWindowWidth } from "@react-hook/window-size";
import DetailedViewCoalitionList from "./detailed-view-coalition-list";

interface TDataProps {
  sortedStates: TSortedState[];
  countdownEnd: Boolean;
  allCompleted: Boolean;
  coalitions: TCoalition[];
  isNominated: Boolean;
}

const TallyViewSection: React.FunctionComponent<TDataProps> = ({
  sortedStates,
  countdownEnd,
  allCompleted,
  coalitions,
  isNominated,
}) => {
  const locale = useLocaleContext();
  const width = useWindowWidth();

  return (
    <div className="mt-10">
      {!countdownEnd ? (
        <p className="font-poppins text-3xl font-bold text-center">
          <Trans>Tally View</Trans>
        </p>
      ) : (
        <div className="flex flex-col items-center lg:items-start">
          <div className="flex items-center">
            <p className="font-poppins text-3xl mr-2 py-2 font-bold text-text-darkest">
              <Trans>Tally View</Trans>
            </p>
            <div className="blink-dot inline-block rounded-md bg-alert px-2 py-1 font-sen text-white">
              {!allCompleted && <Trans>LIVE</Trans>}
            </div>
          </div>
          <p className="font-sen text-text-dark text-lg text-center">
            <Trans>See how many seats each party won</Trans>
          </p>
        </div>
      )}
      <div className="divide-y divide-light-background">
        {sortedStates &&
          sortedStates.map((state) => (
            <div className="py-4" key={state.stateCode}>
              <div className="2xs:flex 2xs:items-center 2xs:justify-between">
                <div>
                  <div className="flex items-center space-x-2">
                    <p className="font-poppins text-lg">
                      {state.stateNameLocale}
                    </p>
                    <IoChevronForwardOutline
                      className="h-5 w-5 text-primary3 cursor-pointer"
                      aria-hidden="true"
                    />
                  </div>
                  {!countdownEnd || state.electionStatus === "unstarted" ? (
                    <p className="font-sen text-text-lightest">
                      {locale == "zh"
                        ? COALITIONS[state.winningParty]?.["zh"] ||
                          state.winningParty
                        : state.winningParty}{" "}
                      <Trans>is incumbent</Trans>
                    </p>
                  ) : state.electionStatus === "ongoing" ? (
                    <div className="flex items-center space-x-1">
                      <div className="bg-alert w-2 h-2 rounded-lg blink-dot"></div>
                      <p className="font-sen text-text-lightest">
                        {locale == "zh"
                          ? COALITIONS[state.mappedCoalitions[0].code]?.[
                              "zh"
                            ] || state.mappedCoalitions[0].code
                          : state.mappedCoalitions[0].code}{" "}
                        <Trans>is leading</Trans>
                      </p>
                    </div>
                  ) : state.electionStatus === "completed" ? (
                    <p className="font-sen text-text-darkest">
                      {locale == "zh"
                        ? COALITIONS[state.winningParty]?.["zh"] ||
                          state.winningParty
                        : state.winningParty}{" "}
                      <Trans>wins 🏆</Trans>
                    </p>
                  ) : state.electionStatus === "tallying" ? (
                    <p className="font-sen text-text-dark">
                      <Trans>Tallying soon...</Trans>
                    </p>
                  ) : null}
                </div>
                <div className="flex space-x-4 mt-2">
                  {(() => {
                    const length = state.mappedCoalitions.slice(0, 3);
                    const test = state.mappedCoalitions.slice(3);
                    const othersValue = test.reduce(
                      (sum, obj) => sum + obj.value,
                      0
                    );
                    const others = coalitions.find(
                      (coalition) => coalition.code == "OTHERS"
                    );
                    const result = [
                      ...length,
                      {
                        ...others,
                        value: othersValue,
                      },
                    ];
                    if (width > 768) {
                      return (
                        state.mappedCoalitions &&
                        state.mappedCoalitions.map(
                          (coalition: TCoalition, index: number) => (
                            <DetailedViewCoalitionList
                              coalition={coalition}
                              index={index}
                              state={state}
                              locale={locale as string}
                              isNominated={isNominated}
                            />
                          )
                        )
                      );
                    } else {
                      return result.map((coalition, index) => (
                        <DetailedViewCoalitionList
                          coalition={coalition as TCoalition}
                          index={index}
                          state={state}
                          locale={locale as string}
                          isNominated={isNominated}
                        />
                      ));
                    }
                  })()}
                </div>
              </div>
              <div className="flex items-center space-x-4 mt-6">
                {state.electionStatus === "ongoing" ||
                state.electionStatus === "completed" ? (
                  <div className="h-1 w-full rounded-md bg-disabled">
                    <div
                      className="relative h-full rounded-l-md transition-all duration-1000 ease-out"
                      style={{
                        width: `${
                          ((state.mappedCoalitions[0].value as number) /
                            state.totalSeats) *
                          100
                        }%`,
                        backgroundColor: `#${rgbHex(
                          state.mappedCoalitions[0].color
                        )}`,
                      }}
                    ></div>
                  </div>
                ) : (
                  <div className="h-1 w-full rounded-md bg-disabled"></div>
                )}
                <span className="whitespace-nowrap font-sen text-text-dark">
                  {state.electionStatus !== "unstarted"
                    ? state.mappedCoalitions[0].value
                    : 0}{" "}
                  / {state.totalSeats} <Trans>seats</Trans>
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TallyViewSection;
