import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Trans } from "@lingui/macro";
import { useLocation } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import ReactGA from "react-ga4";
import useOneLinkUrl, { prnDeepLink } from "./hooks/useOneLinkUrl";
import { useLocaleContext } from "./lib/localeContext";
import QRContainer from "./components/qr-container";
import QRCode from "react-qr-code";

interface IPopUpDialogProps {
  isDialogOpen: boolean;
  setDialogOpen: any;
}

const PRNPopUpDialog: React.FunctionComponent<IPopUpDialogProps> = ({
  isDialogOpen,
  setDialogOpen,
}) => {
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const location = useLocation();
  const path = location.pathname;

  const deepLinkPath =
    prnDeepLink.find((link) => link.path === path)?.deepLinkPath || "/prn-2023";

  const switchToApp = useOneLinkUrl(
    "https://link.luminews.my/ewC9/prn2023",
    deepLinkPath
  );

  const locale = useLocaleContext();

  return (
    <>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-y-auto"
          onClose={closeDialog}
        >
          <div className="min-h-screen px-4 text-center" id="close-dialog">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                <div className="z-20">
                  <div
                    className="cursor-pointer flex justify-end"
                    onClick={() => {
                      ReactGA.event("prn2023_stay_on_web", {
                        page_language: locale,
                      });
                      closeDialog();
                    }}
                  >
                    <IoCloseOutline className="h-8 w-8 text-primary3 mb-4" />
                  </div>
                  <div className="flex space-x-4">
                    <Dialog.Title as="h2" className="my-auto">
                      <p className="font-poppins text-3xl font-bold text-gray-900">
                        <Trans>Continue in app</Trans>
                      </p>
                      <div className="mt-2">
                        <p className="font-sen text-lg font-normal text-gray-500">
                          <Trans>
                            Switch to the Lumi News app to interact with this
                          </Trans>
                        </p>
                        <div className="block py-8">
                          <a
                            href={switchToApp as string}
                            className="rounded-2xl bg-primary p-4 font-sen text-white"
                          >
                            <Trans>Take me there</Trans>
                          </a>
                        </div>
                      </div>
                    </Dialog.Title>

                    <div className="hidden rounded-lg bg-off-white p-4 md:block">
                      <div className="rounded-lg bg-white p-2">
                        <QRCode
                          value={"https://link.luminews.my/ewC9/switchtoapp"}
                          size={300}
                        />
                      </div>
                      <p className="my-2 text-center font-poppins font-bold text-text-darkest">
                        <Trans>Scan the QR code to download</Trans>
                      </p>
                      <div className="flex justify-around">
                        <img
                          src="/three-qrs.png"
                          alt="QR Images"
                          width={350}
                          height={20}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-4 font-poppins text-3xl font-bold text-text-darkest">
                  <Trans>Continue in app</Trans>
                </div>
                <p className="font-sen text-xl text-text-dark">
                  <Trans>
                    Switch to the Lumi News app to interact with this
                  </Trans>
                </p>
                <a
                  href={switchToApp as string}
                  className="mb-4 rounded-2xl bg-primary p-4 text-center font-sen text-white inline-block mt-4"
                  onClick={() => {
                    ReactGA.event("prn2023_dialog_switch_to_app", {
                      page_language: locale,
                    });
                  }}
                >
                  <Trans>Take me there</Trans>
                </a> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PRNPopUpDialog;
