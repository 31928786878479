import { Trans } from "@lingui/macro";
import CountdownTimer from "./countdown-timer";
import ReactGA from "react-ga4";
import FlipMove from "react-flip-move";
import { useLocaleContext } from "../lib/localeContext";
import { useEffect, useRef, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestoreDB } from "../lib/firebase-rt-db";

interface IRightSectionProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  countdownTime: string;
  countdownEnd: Boolean;
  setCountdownEnd: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICommentBoxProps {
  locale: string;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type TChatDetails = {
  id: string;
  username: string;
  contents: any;
  timestamp: any;
};

const RightSection: React.FunctionComponent<IRightSectionProps> = ({
  setDialogOpen,
  countdownTime,
  countdownEnd,
  setCountdownEnd,
}) => {
  const locale = useLocaleContext();
  const [messages, setMessages] = useState<TChatDetails[]>([]);
  const [isLive, setIsLive] = useState(false);
  const firstRender = useRef(false);

  useEffect(() => {
    const stateElectionDocRef = doc(firestoreDB, "chats", "state-elections");

    const unsub = onSnapshot(stateElectionDocRef, (stateElectionDoc) => {
      if (stateElectionDoc.exists()) {
        setIsLive(stateElectionDoc.data().isLive === true);
      } else {
        setIsLive(false);
      }
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (isLive) {
      // Start listening to the "messages" subcollection
      const messagesQuery = query(
        collection(firestoreDB, "chats", "state-elections", "messages"),
        where("type", "==", "message"),
        orderBy("timestamp", "desc"),
        limit(10)
      );

      const unsub = onSnapshot(
        messagesQuery,
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!firstRender.current) {
            firstRender.current = true;
            return;
          }

          const arr: any = [];
          snapshot.docChanges().forEach((change) => {
            const docData = {
              id: change.doc.id,
              ...change.doc.data(),
            };
            if (change.type === "added") {
              arr.push(docData);
            } else if (change.type === "removed") {
              setMessages((messages) =>
                messages.filter((msg) => msg.id !== change.doc.id)
              );
            }
          });

          setMessages((prevMessages) => [...prevMessages, ...arr]);
        }
      );
      return unsub;
    }
  }, [isLive]);

  console.log("PPP", messages);

  return (
    <div className="mb-8 rounded-2xl p-6 border">
      <p className="font-poppins font-bold text-2xl mb-2">
        <Trans>Live Chat</Trans>
      </p>
      <div>
        {!countdownEnd ? (
          <div className="flex flex-col justify-center h-96">
            <p className="text-center font-poppins text-text-medium">
              <Trans>Will be live in</Trans>
            </p>
            <CountdownTimer
              targetDate={countdownTime}
              setCountdownEnd={setCountdownEnd}
            />
          </div>
        ) : messages.length > 0 ? (
          <div className="overflow-y-auto h-[400px] flex flex-col-reverse scrollbar">
            <FlipMove duration={300}>
              {messages.map((m: TChatDetails) => (
                <div key={m.id} className="p-1">
                  {m.contents.username && (
                    <div className="flex space-x-2">
                      <div className="inline-flex flex-col">
                        {m.contents.imageUrl && (
                          <div className="relative h-[20px] w-[20px]">
                            <img
                              className="rounded-full mt-1"
                              src={m.contents.imageUrl}
                              alt="sample avatar"
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <span className="inline-flex font-sen text-text-darkest font-bold">
                          {m.contents.username}{" "}
                          {m.contents.verified && (
                            <div className="relative mx-1 mt-1 h-4 w-4">
                              <img src="/blue-tick.svg" alt="blue-tick" />
                            </div>
                          )}{" "}
                          :
                        </span>{" "}
                        <span className="font-sen text-text-dark">
                          {m.contents.content}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="sticky bottom-0 bg-white p-2">
                <CommentBox
                  locale={locale as string}
                  setDialogOpen={setDialogOpen}
                />
              </div>
            </FlipMove>
          </div>
        ) : (
          <div className="flex flex-col justify-center h-96">
            <p className="text-center font-poppins text-text-medium">
              <Trans>Chat incoming....</Trans>
            </p>
          </div>
        )}
      </div>
      {messages.length === 0 && (
        <CommentBox locale={locale as string} setDialogOpen={setDialogOpen} />
      )}
    </div>
  );
};

const CommentBox: React.FunctionComponent<ICommentBoxProps> = ({
  locale,
  setDialogOpen,
}) => {
  return (
    <div
      className="rounded-lg bg-off-white p-4 flex items-center justify-between cursor-pointer mt-2"
      onClick={() => {
        ReactGA.event("prn2023_chatbox", {
          page_language: locale,
        });
        setDialogOpen(true);
      }}
    >
      <p className="font-sen text-lg text-text-lightest">
        <Trans>Sign in to comment</Trans>
      </p>
      <img
        src={"/asset/paper-plane.svg"}
        width={22}
        height={26}
        alt="share"
        className="text-text-lightest"
      />
    </div>
  );
};

export default RightSection;
