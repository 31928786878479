import { getDatabase, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCNLNhr-iJDUeSsJ4uq6PckbOO8og4EvjY",
  authDomain: "lumi-8b921.firebaseapp.com",
  databaseURL:
    "https://lumi-8b921-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "lumi-8b921",
  storageBucket: "lumi-8b921.appspot.com",
  messagingSenderId: "709065613676",
  appId: "1:709065613676:web:115f4043fa1177578fd349",
  measurementId: "G-YNGM7QHW5M",
};

// const NAMESPACE = process.env.NODE_ENV == "production" ? "production" : "development";
const NAMESPACE = "development";
export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const getRefFromDb = (path: string) => {
  return ref(db, `${NAMESPACE}/${path}`);
};

export const firestoreDB = getFirestore(app);
