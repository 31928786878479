import { useLocaleContext } from "../lib/localeContext";
import OneLinkUrlGenerator from "../lib/oneLinkUrlGenerator";
import { useEffect, useState } from "react";

export default function useOneLinkUrl(initialUrl: string, path: string) {
  const locale = useLocaleContext();

  const [url, setUrl] = useState<string | null>(initialUrl);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const oneLinkGenerator = new OneLinkUrlGenerator({
        oneLinkURL: initialUrl,
        pidKeysList: ["af_pid", "utm_source"],
        campaignKeysList: ["af_c", "utm_campaign"],
        skipList: [],
      });

      oneLinkGenerator.setDeepLinkValue("deep_link_value", path);
      oneLinkGenerator.setAfSub1("url", path);
      oneLinkGenerator.setAdset("language", locale);

      setUrl(oneLinkGenerator.generateUrl());
    }
  }, [initialUrl, path, locale]);

  return url;
}

export const prnDeepLink = [
  {
    path: "/",
    deepLinkPath: "/prn-2023",
  },
  {
    path: "/overall",
    deepLinkPath: "/prn-2023",
  },
  {
    path: "/selangor",
    deepLinkPath: "/prn-2023/SEL",
  },
  {
    path: "/penang",
    deepLinkPath: "/prn-2023/PEN",
  },
  {
    path: "/kelantan",
    deepLinkPath: "/prn-2023/KEL",
  },
  {
    path: "/terengganu",
    deepLinkPath: "/prn-2023/TER",
  },
  {
    path: "/kedah",
    deepLinkPath: "/prn-2023/KED",
  },
  {
    path: "/negeri-sembilan",
    deepLinkPath: "/prn-2023/NS",
  },
];
