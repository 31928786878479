import { Trans } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useLocaleContext } from "../lib/localeContext";

interface CountdownTimerProps {
  targetDate: string;
  setCountdownEnd: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetDate,
  setCountdownEnd,
}) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const difference = +new Date(targetDate) - +new Date();

    if (difference < 0) {
      setCountdownEnd(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const locale = useLocaleContext();

  return (
    <div className="mt-4 flex justify-center space-x-1 md:space-x-2 text-center">
      <div>
        <p className="font-poppins font-bold text-text-darkest 2xs:text-2xl">
          {timeLeft.days}
        </p>
        <p className="font-sen text-text-medium 2xs:text-lg">
          <Trans>day</Trans>
          {locale === "en"
            ? timeLeft.days === 1 || timeLeft.days === 0
              ? ""
              : "s"
            : ""}
        </p>
      </div>
      <p>:</p>
      <div>
        <p className="font-poppins font-bold text-text-darkest 2xs:text-2xl">
          {Math.floor(timeLeft.hours).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </p>
        <p className="font-sen text-text-medium 2xs:text-lg">
          <Trans>hour</Trans>
          {locale === "en"
            ? timeLeft.hours === 1 || timeLeft.hours === 0
              ? ""
              : "s"
            : ""}
        </p>
      </div>
      <p>:</p>
      <div>
        <p className="font-poppins font-bold text-text-darkest 2xs:text-2xl">
          {Math.floor(timeLeft.minutes).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </p>
        <p className="font-sen text-text-medium 2xs:text-lg">
          <Trans>minute</Trans>
          {locale === "en"
            ? timeLeft.minutes === 1 || timeLeft.minutes === 0
              ? ""
              : "s"
            : ""}
        </p>
      </div>
      <p>:</p>
      <div>
        <p className="font-poppins font-bold text-text-darkest 2xs:text-2xl">
          {Math.floor(timeLeft.seconds).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </p>
        <p className="font-sen text-text-medium 2xs:text-lg">
          <Trans>second</Trans>
          {locale === "en"
            ? timeLeft.seconds === 1 || timeLeft.seconds === 0
              ? ""
              : "s"
            : ""}
        </p>
      </div>
    </div>
  );
};

export default CountdownTimer;
