import rgbHex from "rgb-hex";
import { TCoalition, TSortedState } from "../landing-page";
import { COALITIONS } from "../data/coalition";

type TDetailedViewCoalitionListProps = {
  coalition: TCoalition;
  index: number;
  state: TSortedState;
  locale: string;
  isNominated: Boolean;
};

const DetailedViewCoalitionList: React.FunctionComponent<
  TDetailedViewCoalitionListProps
> = ({ coalition, index, state, locale, isNominated }) => {
  return (
    <div key={coalition.id} className="flex flex-col items-center">
      {!isNominated ? (
        <div className="md:w-8 md:h-8 w-6 h-6 bg-disabled rounded-md"></div>
      ) : (
        coalition.imageUrl !== "" && (
          <img
            src={coalition.imageUrl}
            className={`md:w-8 md:h-8 w-6 h-6 rounded-lg mb-2 ${
              state.electionStatus === "ongoing" && "opacity-50"
            }`}
            alt={coalition.partyName}
          />
        )
      )}
      <div className="flex flex-col items-center">
        {isNominated && (
          <p className="font-sen text-[12px] md:text-sm">
            {locale == "zh"
              ? COALITIONS[coalition.code]?.["zh"] || coalition.code
              : coalition.code}{" "}
          </p>
        )}
        <div
          className="p-1 rounded-md"
          style={
            state.electionStatus === "completed" && index === 0
              ? {
                  backgroundColor: `#${rgbHex(
                    state.mappedCoalitions[0].color
                  )}`,
                }
              : undefined
          }
        >
          {isNominated && (
            <p
              className={`text-text-dark font-poppins ${
                state.electionStatus === "ongoing" && index === 0
                  ? "font-bold text-lg"
                  : state.electionStatus === "completed" && index === 0
                  ? "font-bold text-lg text-white"
                  : ""
              }`}
            >
              {coalition.value}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailedViewCoalitionList;
