import { Trans } from "@lingui/macro";
import DownloadNowImage from "./download-now-image";
import QrCodeContainer from "./qr-code-container";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useLocaleContext } from "./lib/localeContext";

const PRNFooter = () => {
  const currentYear = new Date().getFullYear();

  const location = useLocation();
  const path = location.pathname;

  const locale = useLocaleContext();

  return (
    <div className="overflow-hidden bg-light-background py-8 px-4">
      <p className="text-center font-poppins text-2xl font-bold md:text-3xl">
        <Trans>It's better on the app ✨</Trans>
      </p>
      <div
        className="mt-4 flex justify-center md:hidden"
        onClick={() => {
          ReactGA.event("prn2023_download_footer", {
            page_language: locale,
          });
        }}
      >
        <DownloadNowImage locale={locale ? locale : "en"} path={path} />
      </div>
      <div className="mx-auto mt-4 mb-8 hidden w-[350px] md:block">
        <QrCodeContainer
          size="medium"
          initialUrl="https://link.luminews.my/ewC9/prn2023"
          text={<Trans>Scan to download the Lumi News app</Trans>}
        />
      </div>
      <p className="text-center font-sen text-sm text-text-lightest">
        <Trans>
          Source:MyUndi, SPR; supported by additional reporting and surveillance
          of 5 major news site.
        </Trans>
        &nbsp; ©️ Copyright Lumi {currentYear}
      </p>
    </div>
  );
};

export default PRNFooter;
