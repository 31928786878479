import { Trans } from "@lingui/macro";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import useOneLinkUrl, { prnDeepLink } from "../hooks/useOneLinkUrl";
import ReactGA from "react-ga4";
import { useLocaleContext } from "../lib/localeContext";

const QRContainer = () => {
  const location = useLocation();
  const path = location.pathname;

  const locale = useLocaleContext();

  const deepLinkPath =
    prnDeepLink.find((link) => link.path === path)?.deepLinkPath || "/prn-2023";
  const url = useOneLinkUrl(
    "https://link.luminews.my/ewC9/prn2023",
    deepLinkPath
  );

  return (
    <>
      <div
        className="hidden rounded-lg bg-white p-6 md:flex mt-8 md:items-center"
        style={{
          filter: "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
        }}
      >
        <div className="px-12">
          <p className="text-center font-poppins text-2xl font-bold text-text-darkest mb-4">
            <Trans>Get the full results on the app</Trans>
          </p>
          <p className="text-center font-poppins text-base text-text-dark">
            <Trans>
              Get the State Elections results and news from 70+ publishers in
              your pocket
            </Trans>
          </p>
        </div>
        <div className="flex items-center justify-center">
          <div className="rounded-lg bg-off-white p-4">
            <div className="rounded-lg bg-white p-2">
              <QRCode value={url as string} size={300} />
            </div>
            <p className="my-2 text-center font-poppins font-bold text-text-darkest">
              <Trans>Scan the QR code to download</Trans>
            </p>
            <div className="flex justify-around">
              <img
                src="/three-qrs.png"
                alt="QR Images"
                width={190}
                height={20}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 md:hidden">
        <p className="text-center font-poppins text-xl font-bold text-text-darkest mb-4">
          <Trans>Get the full results on the app</Trans>
        </p>
        <p className="text-center font-poppins text-base text-text-dark mb-4">
          <Trans>
            Get the State Elections results and news from 70+ publishers in your
            pocket
          </Trans>
        </p>
        <a
          href={url as string}
          className="flex justify-center rounded-2xl bg-primary py-3 font-sen text-white"
          onClick={() => {
            ReactGA.event("prn2023_open_in_app", {
              page_language: locale,
            });
          }}
        >
          <Trans>Open in app</Trans>
        </a>
      </div>
    </>
  );
};

export default QRContainer;
