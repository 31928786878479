import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import PRNFooter from "./footer";
import { Trans } from "@lingui/macro";
import { Link, Outlet, useLocation } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";
import { i18n } from "@lingui/core";
import ReactGA from "react-ga4";
import { LocaleContext, useLocaleContext } from "./lib/localeContext";
import { stateRoutes } from ".";

type LOCALES = "en" | "zh" | "ms";

const LANGUAGES: { key: LOCALES; value: string }[] = [
  {
    key: "en",
    value: "ENG",
  },
  {
    key: "ms",
    value: "BM",
  },
  {
    key: "zh",
    value: "中文",
  },
];

interface ILocaleOptionProps {
  pathname: Location["pathname"];
  trailingPath: string;
  selectedPath: string;
}

const localeRoutes = ["en", "ms", "zh"];

const PRNLayout: React.FC = () => {
  const location = useLocation();

  const locale =
    localeRoutes.find((route) =>
      location.pathname.split("/")[1].includes(route)
    ) || "en";

  return (
    <LocaleContext.Provider value={locale}>
      <Header />
      <main className="flex-1 mb-8">
        <Outlet />
      </main>

      <PRNFooter />
    </LocaleContext.Provider>
  );
};

export const Header: React.FC<{}> = () => {
  const location = useLocation();
  const { pathname } = location;
  const locale = useLocaleContext();

  const selectedTab =
    stateRoutes.find((route) => route.path === pathname.split("/").pop())
      ?.path || "";
  const trailingPath = "/" + selectedTab;

  const selectedPath = pathname.split("/")[1];

  // Unable to get the locale if 404 page
  const selectedLocale = localeRoutes.find((route) => route === selectedPath);

  return (
    <>
      <div className="mb-3 flex flex-col">
        <nav aria-label="Global">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-6 pt-8">
            <a
              href={`https://luminews.my/${locale}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={"/asset/logo-black.png"}
                height={24}
                width={104}
                alt="logo"
              />
            </a>

            <div className="flex items-center space-x-1 2xs:space-x-8">
              <div>
                <div className="hidden lg:block">
                  <WideLocaleOption
                    pathname={pathname}
                    trailingPath={trailingPath}
                    selectedPath={selectedPath}
                  />
                </div>
                <div className="lg:hidden">
                  <DropdownLocaleOption
                    pathname={pathname}
                    trailingPath={trailingPath}
                    selectedPath={selectedPath}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="border-y mt-4">
        <div className="mx-auto flex h-[58px] max-w-7xl items-center space-x-4 px-6">
          <p className="font-sen text-primary font-bold">
            <Trans>State Election Results</Trans>
          </p>
          <p className="font-sen text-text-medium cursor-pointer">
            <a
              href={`https://luminews.my/${locale}/prn-2023`}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                ReactGA.event("prn2023_election_news_clicked", {
                  page_language: locale,
                });
              }}
            >
              <Trans>Election News</Trans>
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

const WideLocaleOption: React.FC<ILocaleOptionProps> = ({
  pathname,
  trailingPath,
  selectedPath,
}) => {
  // return true if /${string} but not /${string}/${string}
  const regex = /^\/\w+$/;

  return (
    <div className="hidden space-x-2 font-poppins text-text-dark lg:flex">
      {LANGUAGES.map(({ key, value }) => (
        <Link
          to={`/${key + trailingPath}`}
          onClick={() => i18n.activate(key)}
          key={key}
        >
          <button
            key={key}
            className={`font-poppins ${
              selectedPath === key ||
              (((regex.test(pathname) &&
                !localeRoutes.includes(selectedPath)) ||
                pathname === "/" ||
                stateRoutes.some((route) => route.path === selectedPath)) &&
                key === "en")
                ? "font-bold text-primary2"
                : "cursor-pointer hover:font-bold"
            }`}
          >
            {value}
          </button>
        </Link>
      ))}
    </div>
  );
};

const DropdownLocaleOption: React.FC<ILocaleOptionProps> = ({
  pathname,
  trailingPath,
  selectedPath,
}) => {
  // return true if /${string} but not /${string}/${string}
  const regex = /^\/\w+$/;

  return (
    <Menu as="div" className="relative inline-block text-left lg:hidden">
      {({ open }) => (
        <>
          <div className="flex items-center">
            <Menu.Button className="flex h-8 w-14 items-center">
              <p className="text-sm font-bold text-primary2">
                {LANGUAGES.find(
                  (language) => language.key === pathname.split("/")[1]
                )?.value || "ENG"}
              </p>
              <div>
                <IoChevronDownOutline className="h-4 w-4 cursor-pointer text-primary2" />
              </div>
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 mt-2 w-32 origin-top-right rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="pb-4 pt-2">
                {LANGUAGES.map(({ key, value }) => (
                  <Link
                    to={`/${key + trailingPath}`}
                    key={key}
                    onClick={() => i18n.activate(key)}
                  >
                    <Menu.Item key={key}>
                      <span
                        key={key}
                        className={`mx-4 mt-2 block rounded-xl bg-off-white p-2 font-sen ${
                          selectedPath === key ||
                          (((regex.test(pathname) &&
                            !localeRoutes.includes(selectedPath)) ||
                            pathname === "/" ||
                            stateRoutes.some(
                              (route) => route.path === selectedPath
                            )) &&
                            key === "en")
                            ? "font-bold text-primary2"
                            : "cursor-pointer hover:font-bold"
                        }`}
                      >
                        {value}
                      </span>
                    </Menu.Item>
                  </Link>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default PRNLayout;
