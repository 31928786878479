import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import PRNFooter from "./footer";
import { Header } from "./prn-layout";
import { useLocaleContext } from "./lib/localeContext";

const ErrorPage: React.FunctionComponent = () => {
  const locale = useLocaleContext();

  return (
    <>
      <Header />

      <main className="flex-1 mb-8">
        <div className="align-center mx-auto my-24 flex max-w-7xl flex-col px-4 lg:flex-row lg:space-x-12 ">
          <div className="max-w-4xl px-16 lg:px-4">
            <img src="/404.png" alt="Error Page" className="flex-1" />
          </div>
          <div className="flex flex-col justify-center space-y-4 text-center lg:text-left">
            <p className="font-poppins text-3xl font-bold text-text-dark">
              <Trans>'Breaking' news...</Trans>
            </p>
            <p className="font-poppins text-text-medium">
              <Trans>Sorry, the page you're looking for doesn't exist.</Trans>
              &nbsp;
              <Link to={locale ? `/${locale}` : "/"}>
                <a className="cursor-pointer text-primary underline">
                  <Trans>Go back home</Trans>
                </a>
              </Link>
              &nbsp;
              <Trans>or search for something else.</Trans>
            </p>
          </div>
        </div>
      </main>

      <PRNFooter />
    </>
  );
};

export default ErrorPage;
