import React from "react";
import { Helmet } from "react-helmet-async";

interface IReactSEOProps {
  seoTitle: any;
  seoDescription: any;
  seoImage: string;
}

const ReactSEO: React.FunctionComponent<IReactSEOProps> = ({
  seoTitle,
  seoDescription,
  seoImage,
}) => {
  return (
    <Helmet prioritizeSeoTags>
      {/* Standard metadata tags */}
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="fb:app_id" content="1194404434408598" />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:url" content="https://luminews.my/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seoImage} />
      <meta property="og:image:alt" content="Lumi News Malaysia" />
      <meta property="og:site_name" content="Lumi News Malaysia" />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@luminewsmy" />
      {/* End Twitter tags */}
      {/* Additional tags */}
      <meta property="al:web:url" content="luminews.my//" />
      <meta property="al:ios:url" content="luminews://luminews.my//" />
      <meta property="al:ios:app_store_id" content="1569349004" />
      <meta property="al:ios:app_name" content="Lumi News Malaysia" />
      <meta property="al:android:url" content="luminews://luminews.my//" />
      <meta property="al:android:package" content="global.lumi.lumi" />
      <meta property="al:android:app_name" content="Lumi News Malaysia" />
      {/* End Additional tags */}
    </Helmet>
  );
};

export default ReactSEO;
