import CountUp from "react-countup";
import FlipMove from "react-flip-move";
import Banner from "./banner";
import rgbHex from "rgb-hex";
import { TCoalition, TSortedState } from "../landing-page";
import { COALITIONS, coalitionsOrder } from "../data/coalition";
import { useLocaleContext } from "../lib/localeContext";
import { useEffect, useState } from "react";

interface IStateViewSectionProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sortedState: TSortedState;
}

const StateViewSection: React.FunctionComponent<IStateViewSectionProps> = ({
  setDialogOpen,
  sortedState,
}) => {
  const locale = useLocaleContext();

  return (
    <>
      <Banner setDialogOpen={setDialogOpen} />
      <p className="font-bold font-poppins text-xl text-text-darkest mb-4">
        {sortedState?.stateNameLocale}
      </p>
      <FlipMove duration={700}>
        {sortedState?.mappedCoalitions &&
          sortedState.mappedCoalitions
            .slice(0, 3)
            .map((coalition: TCoalition, index: number) => (
              <div key={coalition.id}>
                <div className="flex justify-between">
                  <div className="mb-2 md:flex md:items-center md:space-x-4">
                    <div className="hidden md:block">
                      {coalition.imageUrl ? (
                        <div className="flex flex-col">
                          <div
                            className="relative h-[20px] w-[20px]"
                            style={{
                              filter:
                                "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
                            }}
                          >
                            <img
                              className="rounded-full"
                              src={coalition.imageUrl}
                              alt={coalition.id}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="h-[20px] w-[20px] rounded-full"
                          style={{
                            backgroundColor: `#${rgbHex(coalition.color)}`,
                          }}
                        ></div>
                      )}
                    </div>
                    <p className="font-poppins font-bold text-text-darkest">
                      {COALITIONS[coalition.code]?.[locale as string] ||
                        coalition.partyName}
                    </p>
                  </div>
                  <div className="flex space-x-12 font-poppins text-sm">
                    <p className="font-bold">
                      <CountUp
                        key={coalition.id}
                        start={coalition.value}
                        end={coalition.value}
                        duration={1}
                      />
                    </p>
                  </div>
                </div>
                <div className="mb-6 h-8 w-full rounded-md bg-light-background">
                  <div
                    className={`relative h-full rounded-l-md transition-all duration-1000 ease-out`}
                    style={{
                      width: `${
                        (coalition.value / sortedState.totalSeats) * 100
                      }%`,
                      backgroundColor: `#${rgbHex(coalition.color)}`,
                    }}
                  >
                    {sortedState.electionStatus === "completed" &&
                    index === 0 ? (
                      <div className="running-emote float-right mr-[-15px] mt-[-10px] text-5xl">
                        🏆
                      </div>
                    ) : (
                      <div className="running-emote float-right mr-[-15px] text-2xl">
                        🏃‍♂️
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
      </FlipMove>
    </>
  );
};

export default StateViewSection;
