import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";
import { Trans } from "@lingui/macro";
import { useLocation } from "react-router-dom";
import useOneLinkUrl, { prnDeepLink } from "./hooks/useOneLinkUrl";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useLocaleContext } from "./lib/localeContext";

const PRNSwitchToAppDialog: React.FunctionComponent = () => {
  const location = useLocation();
  const path = location.pathname;

  const deepLinkPath =
    prnDeepLink.find((link) => link.path === path)?.deepLinkPath || "/prn-2023";

  const switchToApp = useOneLinkUrl(
    "https://link.luminews.my/ewC9/prn2023",
    deepLinkPath
  );

  const width = useWindowWidth();

  const [isOpen, setIsOpen] = useState(false);

  const [cookies, setCookie] = useCookies(["cookieKey"]);

  const locale = useLocaleContext();

  useEffect(() => {
    const timeOutDelay =
      cookies["cookieKey"] === undefined ? 5000 : 15 * 60 * 1000;
    if (width < 768 && !isOpen) {
      setTimeout(() => {
        setIsOpen(true);
        ReactGA.event("prn2023_tally_page_download_drawer", {
          page_language: locale,
        });
      }, timeOutDelay);
    }
  }, [isOpen, width]);

  const hideDialog = () => {
    setIsOpen(false);
    setCookie("cookieKey", "prn2023Cookie", {
      maxAge: 15 * 60 * 1000,
    });
  };

  return (
    <>
      <Transition appear show={isOpen && width < 768} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex h-screen items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="fixed bottom-0 flex max-h-full w-full flex-col rounded-t-2xl bg-white p-6 text-center">
                  <Dialog.Title>
                    <div className="mb-4 font-poppins text-xl font-bold leading-6 text-gray-900">
                      <Trans>
                        Get State Election news & results straight to your phone
                      </Trans>
                    </div>
                    <p className="font-sen text-text-dark">
                      <Trans>Only available on the Lumi News app</Trans>
                    </p>
                    <div className="flex justify-around mb-6">
                      <img src="/prn2023-ballot.png" alt="prn2023-ballot" />
                    </div>
                  </Dialog.Title>
                  <a
                    href={switchToApp as string}
                    className="mb-4 rounded-2xl bg-primary p-4 text-center font-sen text-white"
                    onClick={() => {
                      ReactGA.event("switch_to_app_prn2023_tally_page_drawer", {
                        page_language: locale,
                      });
                    }}
                  >
                    <Trans>Get app for free</Trans>
                  </a>
                  <button
                    type="button"
                    className="text-center font-sen text-xs text-text-lightest"
                    onClick={() => {
                      ReactGA.event("stay_on_web_prn2023_tally_page_drawer", {
                        page_language: locale,
                      });
                      hideDialog();
                    }}
                  >
                    <Trans>Maybe later</Trans>
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PRNSwitchToAppDialog;
