import * as React from "react";
import useOneLinkUrl, { prnDeepLink } from "./hooks/useOneLinkUrl";

interface IDownloadNowImageProps {
  path: string;
  size?: "large" | "small";
  locale?: string;
  initialUrl?: string;
  eventName?: string;
}

const SIZE = {
  small: {
    width: 170,
    height: 68,
  },
  large: {
    width: 293,
    height: 120,
  },
};

const LANGUAGE_IMAGE: { [key: string]: string } = {
  en: "/download-button.png",
  ms: "/download-button-bm.png",
  zh: "/download-button-zh.png",
};

const DownloadNowImage: React.FunctionComponent<IDownloadNowImageProps> = ({
  path,
  size = "large",
  locale = "en",
  initialUrl = "https://link.luminews.my/ewC9/prn2023",
}) => {
  const deepLinkPath =
    prnDeepLink.find((link) => link.path === path)?.deepLinkPath || "/prn-2023";
  const url = useOneLinkUrl(initialUrl, deepLinkPath);

  return (
    <a href={url as string}>
      <img
        src={LANGUAGE_IMAGE[locale]}
        alt="Read More"
        width={SIZE[size].width}
        height={SIZE[size].height}
        placeholder="blur"
      />
    </a>
  );
};

export default DownloadNowImage;
